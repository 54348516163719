import React from 'react';
import { graphql } from 'gatsby';
import { Location } from '@reach/router';
import MetaData from '../../components/Layout/MetaData.jsx';
import WhyIwoca from 'aphrodite-shared/ui/WhyIwoca/WhyIwoca';

import Questions from '../uk/common/Questions/Questions';
import Footer from '../../components/Footer/Footer';
import { fetchIsLoggedIn } from '../../util/authHelpers';
import HeroBannerSection from '../../components/LegacyPage/HeroBannerSection';

import iwocaPageStyles from '../uk/iwocaPage.module.scss';
import styles from './Accountants.module.scss';
import NavBar from '../../components/NavBar/NavBar';

import { v4 } from 'uuid';

import FeatureBarAccountants from './FeatureBarAccountants/FeatureBarAccountants.jsx';

const Accountants = ({ data }) => {
    const { contentfulPageAccountants: pageData } = data;
    const {
        title,
        metaDescription,
        contactUsSection,
        allowSearchEngineScraping = true,
        includeTitlePrefixSuffix,
        url
    } = pageData;

    const isLoggedIn = fetchIsLoggedIn();

    const isBroker =
        typeof window !== 'undefined' &&
        window.location.pathname === '/broker/';

    const heroBannerButtonsAccountants = [
        {
            id: v4(),
            url: '/introducer/signup/',
            buttonText: 'Become an introducer',
            dataGaId: 'accountants_hero__become',
            buttonClass: styles.heroButton
        },
        {
            id: v4(),
            url: 'https://meetings.hubspot.com/m-white/iwoca-cbils-product/',
            buttonText: 'Get in touch',
            colour: 'tertiary',
            newTab: true,
            dataGaId: 'accountants_hero__get_in_touch',
            buttonClass: styles.secondaryButton
        }
    ];

    const heroBannerButtonsBroker = {
        id: v4(),
        url: '/introducer/signup/',
        buttonText: 'Become an introducer',
        dataGaId: 'accountants_hero__become',
        buttonClass: styles.heroButton
    };

    // Object is in replacement of header data having to come from legacy page in contentful\\
    const headerBannerDataAccountants = {
        headline: pageData.heroBusinessOwnerTitle,
        subtitleText: {
            subtitle: pageData.heroBusinessOwnerSubtitle
        },
        content: [{ image: pageData.heroBusinessOwnerImage }],
        button: heroBannerButtonsAccountants
    };

    const headerBannerDataBroker = {
        headline: pageData.heroBusinessOwnerTitle,
        subtitleText: {
            subtitle: pageData.heroBusinessOwnerSubtitle
        },
        content: [{ image: pageData.heroBusinessOwnerImage }],
        button: heroBannerButtonsBroker
    };

    return (
        <div className={iwocaPageStyles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    includeTitlePrefixSuffix,
                    url
                }}
            />
            <NavBar />
            <Location>
                {({ location }) => {
                    switch (location.pathname) {
                        case '/accountants':
                        case '/accountants/':
                            return (
                                <HeroBannerSection
                                    data={headerBannerDataAccountants}
                                    loggedIn={isLoggedIn}
                                    broker={true}
                                />
                            );
                        case '/broker':
                        case '/broker/':
                            return (
                                <HeroBannerSection
                                    data={headerBannerDataBroker}
                                    loggedIn={isLoggedIn}
                                    broker={true}
                                />
                            );
                        default:
                            return null;
                    }
                }}
            </Location>

            <FeatureBarAccountants isBroker={isBroker} />

            <WhyIwoca
                className={styles.whyIwoca}
                title={pageData.whyIwocaTitle}
                points={pageData.whyIwocaPoints}
            />

            <Questions
                title={contactUsSection.title}
                content={contactUsSection.content.content}
                email={pageData.contactUsSection.email}
                phoneNumber={pageData.contactUsSection.phoneNumber}
                showApplyButton={pageData.contactUsSection.showApplyButton}
                showJobsButton={pageData.contactUsSection.showJobsButton}
                jobCTAText={pageData.jobCtaText}
                jobLink={pageData.jobLink}
                loanCTAText={pageData.loanCtaText}
                hideHelpModal={true}
            />
            <Footer />
        </div>
    );
};

export default Accountants;

export const query = graphql`
    query AccountantsPageQuery($id: String!) {
        contentfulPageAccountants(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            includeTitlePrefixSuffix
            navigation {
                ... on Node {
                    ... on ContentfulNavbar {
                        name
                        menuItems {
                            name
                            item {
                                url
                                title
                            }
                        }
                    }
                    ... on ContentfulHeader {
                        ...Header
                    }
                }
            }
            heroBusinessOwnerTitle
            heroBusinessOwnerSubtitle
            heroBusinessOwnerButton

            heroBusinessOwnerImage {
                fixed(width: 1800, quality: 90) {
                    ...GatsbyContentfulFixed
                }
            }

            whyIwocaTitle
            whyIwocaPoints {
                ...whyIwoca
            }
            whyIwocaButton

            contactUsSection {
                ...contactUsSection
            }
        }
    }

    fragment trustpilotRating on ContentfulTrustpilotRating {
        trustpilotRating
        numberOfReviews
        copy
    }

    fragment whyIwoca on ContentfulWhyIwoca {
        title
        description
    }

    fragment contactUsSection on ContentfulContactUsQuestions {
        id
        title
        showApplyButton
        showJobsButton
        email
        phoneNumber
        content {
            content
        }
        email
        showApplyButton
        showJobsButton
        phoneNumber
    }

    fragment pressReleaseSection on ContentfulPressReleaseSection {
        trustpilotText
        pressPhotos {
            name
            photos {
                file {
                    url
                }
            }
        }
        trustpilotRating {
            ...trustpilotRating
        }
    }
    fragment faqSection on ContentfulSectionFaQs {
        title
        subtitle {
            subtitle
        }
        faqs {
            question
            answer
            color
            longAnswer {
                longAnswer
            }
        }
    }
    fragment trustPilot on trustpilot {
        numberOfReviews {
            total
        }
        score {
            trustScore
        }
    }
`;

// heroSecondaryButton {
//     buttonText
//     url
//     descriptionText
// }