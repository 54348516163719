import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import DynamicTrustPilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import styles from './FeatureBarAccountants.module.scss';

const useFeatureBar = () => {
    const { contentfulFeaturesBar } = useStaticQuery(
        graphql`
            {
                contentfulFeaturesBar(
                    featuresBar: { eq: "CIBLS feature bar" }
                ) {
                    feature {
                        featureTitle {
                            featureTitle
                        }
                        description {
                            description
                        }
                    }
                    featuresBar
                    trustedLogo {
                        fluid(maxWidth: 200, quality: 90) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `
    );

    return contentfulFeaturesBar;
};

const FeatureBarAccountants = ({ isBroker }) => {
    const featuresBar = useFeatureBar();

    const imageURL = isBroker
        ? 'https://images.ctfassets.net/835lsoe3tdrh/7qwCjbhLNzORLoDZs4250S/215c3568241f907c7e8b5810d0b3f9d1/nacfb.png?h=250'
        : 'https://images.ctfassets.net/835lsoe3tdrh/LtGTOedFFpXBdlaCCMAAn/d4a55a72d4b5e4e295686063e264dbb1/Group_2147.png';
    const imageAlt = isBroker ? 'NACFB' : 'Xero Awards winner';

    return (
        <>
            <div className={styles.Features}>
                <div className={styles.featuresBarSection}>
                    <div className={styles.USPContainer}>
                        {featuresBar.trustedLogo && (
                            <a
                                href="https://www.british-business-bank.co.uk/ourpartners/recovery-loan-scheme/"
                                target="_blank"
                                rel="noopener noreferrer">
                                <Img
                                    fluid={featuresBar.trustedLogo.fluid}
                                    className={styles.bbb}
                                />
                            </a>
                        )}
                        <DynamicTrustPilot
                            className={styles.DesktopTrustpilot}
                        />

                        <div className={styles.xeroLogo}>
                            <img src={imageURL} alt={imageAlt} />
                        </div>
                    </div>
                </div>
                <div className={styles.mobileBBB}>
                    {featuresBar.trustedLogo && (
                        <a
                            href="https://www.british-business-bank.co.uk/ourpartners/recovery-loan-scheme/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div className={styles.bbbContainer}>
                                <Img
                                    fluid={featuresBar.trustedLogo.fluid}
                                    className={styles.bbb}
                                />
                            </div>
                        </a>
                    )}
                </div>
                <DynamicTrustPilot className={styles.MobileTrustpilot} />
            </div>
        </>
    );
};

export default FeatureBarAccountants;
